<template>
  <ArticleContentTemplate
    :breadcrumb="breadcrumb"
    :title="detail.title"
    :content="detail.content"
  />
</template>

<script>
import { apiGetUserArticle } from "@/api/article";
import ArticleContentTemplate from "@/components/ArticleContentTemplate.vue";

export default {
  name: "SolutionServiceDetails",
  components: {
    ArticleContentTemplate,
  },
  data() {
    return {
      detail: {},
    };
  },
  computed: {
    breadcrumb() {
      const { title, categoryName, categoryId } = this.detail;
      return [
        { id: 1, path: "/solution-service", title: "解決方案及服務" },
        {
          id: 2,
          path: `/solution-service?category=${categoryId}`,
          title: categoryName,
        },
        { id: 3, title },
      ];
    },
    articleId() {
      const { id } = this.$route.params;
      return id;
    },
  },
  methods: {
    async getDetails(articleId) {
      try {
        this.$vLoading(true);
        const res = await apiGetUserArticle(articleId);
        this.detail = res.data.data;
      } catch (error) {
        // regardless
      } finally {
        this.$vLoading(false);
      }
    },
  },
  watch: {
    articleId(val) {
      if (val) {
        this.getDetails(val);
      }
    },
  },
  created() {
    this.getDetails(this.articleId);
  },
};
</script>
